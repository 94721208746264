import { Link, graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ellipsis } from '../Helpers/helpers'
import './JobOpenings.scss'
import UnboxingDoodle from '../../assets/images/UnboxingDoodle.png'

const JobOpenings = (props: any): JSX.Element => {
  const careersPageData = useStaticQuery(graphql`
    query careersPageQuery {
      socialMediaLinks {
        links {
          id
          link
          category
          name
        }
      }
    }
  `)

  const careersocialLink =
    careersPageData && careersPageData.socialMediaLinks
      ? careersPageData.socialMediaLinks.links.filter((x: any) => x.name.includes('Career'))
      : ''

  const JobsData = props.data

  const [jobs, setJobs] = useState<any>(null)
  const [filtering, setFiltering] = useState<boolean>(false)
  const [filter, setFilter] = useState<string>('All')

  const tags = ['All']

  // extract tags from data

  JobsData.forEach((item: any) => {
    const tag = item.node.context.data.employmentType.name
    if (!tags.includes(tag)) {
      tags.push(tag)
    }
  })

  const toggleFiltering = (): void => {
    setFiltering(!filtering)
  }

  const selectFilter = (tag: string): void => {
    if (tag !== 'All') {
      const filteredJobs = JobsData.filter((j: any) => JSON.stringify(j.node.context.data.employmentType).includes(tag))
      setJobs(filteredJobs)
    } else {
      setJobs(JobsData)
    }
    setFilter(tag)
    setFiltering(false)
  }

  useEffect(() => {
    setJobs(JobsData)
  }, [])

  return (
    <div className="job-openings">
      <div className="job-thread">
        <span className="page-tag">Careers</span>
        <div className="job-header">
          <h1> Join our team</h1>
          <a href={careersocialLink[0] ? careersocialLink[0].link : ''} target="_blank" rel="noopener noreferer">
            <div className="join-fb-page">
              <span>
                Join us on Facebook <i className="la la-facebook" />
              </span>
            </div>
          </a>
        </div>

        <div className="job-filter">
          <div className={`job-filter-input ${filter !== 'All' ? 'active' : ''}`} onClick={toggleFiltering}>
            <span>Openings: {filter}</span>
            {!filtering ? <i className="la la-angle-down" /> : <i className="la la-angle-up" />}
          </div>

          {filtering && (
            <div className="job-filter-dropdown fade-in-bottom">
              {tags &&
                tags.map((tag: any, index: number) => {
                  return (
                    <span
                      key={index}
                      onClick={() => {
                        selectFilter(tag)
                      }}
                    >
                      {tag}
                    </span>
                  )
                })}
            </div>
          )}
        </div>

        {jobs && jobs.length > 0 ? (
          jobs.map((job: any, index: number) => {
            let jobInfo = job.node.context.data
            return (
              <Link to={job.node.path}>
                <div className="job-opening-card fade-in" key={index}>
                  <div className="job-opening-header">
                    <div className="job-title-info">
                      <span>{jobInfo.title}</span>
                      <small>
                        Posted {' ' + moment(jobInfo.created_at).fromNow()} <i className="la la-circle" /> Apply Before{' '}
                        {moment(jobInfo.apply_before_at).format('MMM D')}
                      </small>
                    </div>

                    <div className="job-tags">
                      <small className="tag">{jobInfo.employmentType.name}</small>
                    </div>
                  </div>

                  <div className="job-description" dangerouslySetInnerHTML={{ __html: ellipsis(jobInfo.job_description, 120) }} />
                </div>
              </Link>
            )
          })
        ) : (
          <div id="careers-no-results">
            <img src={UnboxingDoodle} alt="no vacancy" className="fade-in-bottom dl-2" />
            <p className="fade-in-bottom dl-4">
              We do not have any availability for <br /> this department right now.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default JobOpenings
