import React from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import JobHeroBanner from '../../components/JobOpenings/JobHeroBanner'
import JobOpenings from '../../components/JobOpenings/JobOpenings'
import SEO from '../../components/SEO/SEO'
import './CareersPage.scss'
import { graphql, useStaticQuery } from 'gatsby'

const CareersPage = () => {
  const queryData = useStaticQuery(graphql`
    query allCareers {
      allSitePage(filter: { context: { category: { eq: "careers" } } }) {
        edges {
          node {
            path
            context {
              data {
                id
                title
                job_description_label
                job_description
                qualifications_label
                qualifications
                location
                created_at
                tags
                apply_before_at
                employmentType {
                  name
                }
              }
            }
          }
        }
      }
    }
  `)

  const careersData = queryData.allSitePage.edges ? queryData.allSitePage.edges : []

  return (
    <>
      <SEO title="Careers | Ayala Land International Sales" />
      <Header solid={true} />
      <div className="careers-page">
        <div className="careers-page-content">
          <div className="content left-container">
            <JobHeroBanner />
          </div>
          <div className="content right-container">
            <JobOpenings data={careersData} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default CareersPage
